<template>
    <Support />
</template>

<script>
import Support from '@apps/Support/components/HeaderBtn.vue'
export default {
    name: 'HeaderSupport',
    components: {
        Support
    }
}
</script>